import React, { useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { makeStyles, AppBar, Tabs, Tab } from '@material-ui/core'
import { withRouter, RouteComponentProps, Redirect, Link, Switch, Route } from 'react-router-dom'

import UsersContainer from 'components/Settings/Team/Users/UsersContainer'
import GroupsContainer from 'containers/Settings/GroupsContainer'
import { CurrentUserDocument } from 'generated/graphql'
import { getDefaultLandingURL } from 'utils'
import { URLS } from 'utils/constants'

const useStyles = makeStyles({
  appBar: {
    marginBottom: 15,
  },
  tabOverride: {
    whiteSpace: 'nowrap',
    minWidth: 0,
    fontSize: '1.4rem',
  },
})

const TeamPermissions: React.FC<RouteComponentProps> = ({ location }) => {
  const client = useApolloClient()
  const currentUser = client.readQuery({ query: CurrentUserDocument }).currentUser
  const tabFromPath = location.pathname.includes('users') ? 'Team' : 'Groups'
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(tabFromPath)
  const renderUsers = () => <UsersContainer currentUser={currentUser} />
  const renderGroups = () => <GroupsContainer currentUser={currentUser} />
  const tabs = [{ label: 'Team', path: URLS.ORG_SETTINGS.USERS }]
  if (currentUser.isAdmin) {
    tabs.push({ label: 'Groups', path: URLS.ORG_SETTINGS.GROUPS })
  }
  return (
    <>
      <AppBar className={classes.appBar} position="static" color="default">
        <Tabs value={currentTab} variant="fullWidth" scrollButtons="auto">
          {tabs.map(({ label, path }) => {
            return (
              <Tab
                style={{ pointerEvents: 'auto' }}
                key={label}
                classes={{ root: classes.tabOverride }}
                label={label}
                value={label}
                component={Link}
                onClick={() => setCurrentTab(label)}
                to={path}
              />
            )
          })}
        </Tabs>
      </AppBar>
      <Switch>
        <Route exact path={URLS.ORG_SETTINGS.USERS} render={renderUsers} />
        {currentUser.isAdmin ? (
          <Route exact path={URLS.ORG_SETTINGS.GROUPS} render={renderGroups} />
        ) : (
          <Redirect to={{ pathname: getDefaultLandingURL(currentUser) }} />
        )}
      </Switch>
    </>
  )
}

export default withRouter(TeamPermissions)
